import React from 'react';
import { ApiVmStatus, BackupSetState, BackupTaskState, HostState, MonitoringIncidentStatus, OrgUserRole, TemplateState } from 'generated';
import StringTidy from './StringTidy';
import { useTranslation } from 'react-i18next';

type BadgeColor = "success" | "secondary" | "primary" | "warning" | "danger" | "info"; // All allowed colors

interface StatusConfig {
  color: BadgeColor;
  text: string;
}

interface StatusBadgeProps {
  status: string | boolean;
  className?: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, className }) => {

    const {t} = useTranslation()

    const statusConfigMap: Record<string, StatusConfig> = {
        ready: { color: "success", text: "Ready" },
        pending: { color: "primary", text: "Pending" },
        running: { color: "success", text: "Running" },
        stopped: { color: "secondary", text: "Stopped" },
        submitted: { color: "primary", text: "Provisioning" },
        hostOffline: { color: "secondary", text: "Host Offline" },
        [ApiVmStatus.SUSPENDED]: { color: "warning", text: t(`virtualMachine.status.${ApiVmStatus.SUSPENDED }`) },
        [MonitoringIncidentStatus.ACKNOWLEDGED]: { color: "warning", text: t(`MonitoringIncidentStatus.${MonitoringIncidentStatus.ACKNOWLEDGED}`) },
        [MonitoringIncidentStatus.CLOSED]: { color: "secondary", text: t(`MonitoringIncidentStatus.${MonitoringIncidentStatus.CLOSED}`) },
        [MonitoringIncidentStatus.OPEN]: { color: "danger", text: t(`MonitoringIncidentStatus.${MonitoringIncidentStatus.OPEN}`) },
        [HostState.ONLINE]: { color: "success", text: t(`HostState.${HostState.ONLINE}`) },
        [HostState.JOIN_PENDING]: { color: "warning", text: t(`HostState.${HostState.JOIN_PENDING}`) },
        [HostState.OFFLINE]: { color: "danger", text: t(`HostState.${HostState.OFFLINE}`) },
        [TemplateState.DRAFT]: { color: "primary", text: StringTidy(TemplateState.DRAFT) },
        [TemplateState.PROCESSING]: { color: "warning", text: StringTidy(TemplateState.PROCESSING) },
        [TemplateState.READY]: { color: "success", text: StringTidy(TemplateState.READY) },
        [BackupTaskState.SKIPPED]: { color: "warning", text: t(`BackupTaskState.${BackupTaskState.SKIPPED}`) },
        [BackupTaskState.DELETED]: { color: "secondary", text: t(`BackupTaskState.${BackupTaskState.DELETED}`) },
        [BackupTaskState.PENDING]: { color: "primary", text: t(`BackupTaskState.${BackupTaskState.PENDING}`) },
        [BackupTaskState.COMPLETE]: { color: "success", text: t(`BackupTaskState.${BackupTaskState.COMPLETE}`) },
        [BackupTaskState.IN_PROGRESS]: { color: "primary", text: t(`BackupTaskState.${BackupTaskState.IN_PROGRESS}`) },
        [BackupTaskState.FAILED]: { color: "danger", text: t(`BackupTaskState.${BackupTaskState.FAILED}`) },
        [BackupSetState.ENABLED]: { color: "success", text: t(`BackupSetState.${BackupSetState.ENABLED}`) },
        [BackupSetState.DISABLED]: { color: "danger", text: t(`BackupSetState.${BackupSetState.DISABLED}`) },
        [BackupSetState.ARCHIVED]: { color: "secondary", text: t(`BackupSetState.${BackupSetState.ARCHIVED}`) },
        [OrgUserRole.OWNER]: { color: "primary", text: t(`OrgUserRole.${OrgUserRole.OWNER}`) },
        [OrgUserRole.USER]: { color: "primary", text: t(`OrgUserRole.${OrgUserRole.USER}`) },
        [OrgUserRole.PENDING_INVITE]: { color: "secondary", text: t(`OrgUserRole.${OrgUserRole.PENDING_INVITE}`) },
        [OrgUserRole.EXPIRED_INVITE]: { color: "danger", text: t(`OrgUserRole.${OrgUserRole.EXPIRED_INVITE}`) },
        "200": { color: "success", text: "200" },
        true: { color: "success", text: "True" },
        false: { color: "danger", text: "False" }
    };

    const defaultConfig: StatusConfig = { color: "secondary", text: String(status) };
    const { color, text } = statusConfigMap[status as string] || defaultConfig;

    return (
        <span className={`${className} badge border border-${color}-subtle text-${color} fs-12`}>
            {text}
        </span>
    );
};

export default StatusBadge;
