/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ApiVmStatus {
    UNKNOWN = 'unknown',
    STOPPED = 'stopped',
    RUNNING = 'running',
    PAUSED = 'paused',
    MISSING = 'missing',
    PAUSING = 'pausing',
    RESUMING = 'resuming',
    STARTING = 'starting',
    STOPPING = 'stopping',
    SUBMITTED = 'submitted',
    PENDING = 'pending',
    CREATE_FAILED = 'createFailed',
    HOST_OFFLINE = 'hostOffline',
    HOST_ARCHIVED = 'hostArchived',
    DELETE_REQUESTED = 'deleteRequested',
    DELETED = 'deleted',
    SUSPENDED = 'suspended',
}
