import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { HostDetail} from "generated";
import { Link } from "react-router-dom";
import { OrgFlags } from "Components/Gallium/Flags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";

interface DiskInfoModalProps {
    hypervisor: HostDetail,
}

const AddBackupStoragePoolCard: React.FC<DiskInfoModalProps> = ({ hypervisor }) => {
    const { t } = useTranslation();

    if (!hypervisor?.online || !hypervisor?.availableActions.backupPoolProvision) return (null)
    return ( 
        <OrgFlags renderIfExists={true} flag='BACKUP_EARLY_ACCESS'>
            <Card>
                <CardBody className="p-5 mt-5 mb-5 text-center">
                    <Link to={`/hypervisors/${hypervisor.slug}/storage?role=BACKUP`} className="text-dark">
                        <Button color="primary">
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-container-storage-circle-plus"]} className='me-2'/>
                            {t("hypervisor.storagePool.createBackupPoolButton")}
                        </Button>
                    </Link>
                </CardBody>
            </Card>
        </OrgFlags>
    );
}

export default AddBackupStoragePoolCard