import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { NavLink, Button, Card, CardBody, CardHeader, Container, Nav, NavItem, Progress, Table, TabContent, TabPane } from 'reactstrap';
import Loader from 'Components/Gallium/Loader';
import { useFileTransferManager } from 'GalliumFileTransferManager/wrapper';
import { round } from "lodash"
import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import classnames from "classnames";
import HypervisorExportDisk from './export';
import { useGetHost } from 'GalliumAPIHooks/Host/HostHooks';


const HypervisorImportDisk = () => {
    const { t } = useTranslation()
    const hypervisorSlug = useParams().hostSlug
    const {data} = useGetHost(hypervisorSlug)
    const {ftmReady, hostAvailable, activeTransfers, connectToHost, beginUpload} = useFileTransferManager()

    useEffect(() => {
        connectToHost(hypervisorSlug);
    }, [ftmReady]);
    const isHostConnected = hostAvailable(hypervisorSlug)

    document.title = "File Transfer Manager" + " | Gallium";
    const crumbs = [
        {
            name: t("mainNav.hypervisors"),
            link: "/hypervisors"
        },
        {
            name: data?.name || "Hypervisor",
            link: `/hypervisors/${data?.slug}` || "#"
        },
        {
            name: "File Transfer Manager"
        }
    ]

    const [searchParams] = useSearchParams()
    const [galliumNavTab, setgalliumNavTab] = useState(searchParams.get("tab") || "ALL");
    const galliumNavToggle = (tab) => {
        if (galliumNavTab !== tab) {
            setgalliumNavTab(tab);
        }
    };

    const tabs = (
        <Nav className="nav-tabs-custom border-bottom-0 pt-1" role="tablist">
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "ALL", })} onClick={() => { galliumNavToggle("ALL"); }} >
                All Transfers
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: galliumNavTab === "EXPORT", })} onClick={() => { galliumNavToggle("EXPORT"); }} >
                Export
                </NavLink>
            </NavItem>
        </Nav>
    )

    return (
        <div className="page-content">
            <Container fluid>
                <GalliumBreadcrumb title="File Transfer Manager" crumbs={crumbs} tabs={tabs}>
                    {ftmReady && isHostConnected ? (
                        <Button color="primary" className='float-end' onClick={(_e) => beginUpload(hypervisorSlug)}>Import</Button>
                    ):(null)}
                </GalliumBreadcrumb>
                <TabContent activeTab={galliumNavTab} className="text-muted">
                    <TabPane tabId="ALL" id="nav-light-home">
                        {ftmReady && isHostConnected? (
                            <Card>
                                <CardHeader className=''>
                                    <h4 className="card-title mb-0">All Transfers</h4>
                                    {/* {isHostConnected ? "Connected" : "Not Connected"}  */}
                                </CardHeader>
                                <CardBody >
                                    <div className="table-responsive table-card">
                                        <Table className="align-middle table-nowrap mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" className="col-7">File</th>
                                                    <th scope="col" className="col-5 text-end">Status</th>
                                                </tr>
                                            </thead>
                                            {activeTransfers.length > 0 ? (
                                                <tbody>
                                                    {activeTransfers.map((file, index) => (
                                                        <tr key={index}>
                                                            <td className='col-6'>
                                                                {file.fileHandle.name}
                                                            </td>
                                                            <td className='col-3 text-end'>
                                                                {!file.completed ? (
                                                                    <React.Fragment>
                                                                        <span>{file.type === "download" ? "Downloading" : "Uploading"} {round(file.pct,1).toFixed(1)} %</span>
                                                                        <Progress color="primary" className="progress-xl" value={file.pct} striped animated />
                                                                    </React.Fragment>
                                                                ):(
                                                                    "Complete"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            ):(
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={2} className='text-center'>
                                                        No Transfers in Progress
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        ) : (
                            <Loader />
                        )}  
                    </TabPane>
                    <TabPane tabId="EXPORT" id="nav-light-home">
                        <HypervisorExportDisk />
                    </TabPane>
                </TabContent>
                
            </Container>
        </div>
        


    )
};

export default HypervisorImportDisk